import React, { FC } from "react"
import { useDropzone } from "react-dropzone"
import cn from "../utils/cn"

interface UploaderProps {
  id?: string,
  className?: string,
  onDrop: any
}

const Uploader: FC<UploaderProps> = ({ id, className, onDrop }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ onDrop })

  return (
    <div
      className={cn("border-gray-500 border-dashed border-2 p-12 rounded text-center cursor-pointer hover:bg-gray-100", className)}
      {...getRootProps()}
    >
      <input
        name={id}
        id={id}
        {...getInputProps()}
      />

      {isDragActive
        ? <p>Slipp vedlegg her...</p>
        : <p>Dra filer over her for å laste opp, eller trykk for å velge.</p>}
    </div>
  )
}

export default Uploader
