import React, { FC, useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import Input from "../components/Input"
import Uploader from "../components/Uploader"
import ActivityIndicator from "../components/ActivityIndicator"
import config from "../config"

toast.configure({
  hideProgressBar: true
})

const KundeserviceForm: FC = () => {
  const { register, handleSubmit, errors } = useForm()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ attachments, setAttachments ] = useState<File[]>([])

  const onDrop = useCallback((files) => {
    setAttachments([ ...attachments, ...files ])
  }, [ attachments ])

  const removeAttahment = (name: string) => {
    setAttachments([ ...attachments.filter((a) => a.name !== name) ])
  }

  const submit = async (data: any) => {
    setIsLoading(true)

    const formData = new FormData()

    for(const field in data) {
      formData.append(field, data[field])
    }

    attachments.forEach((attachment) => {
      formData.append("attachments", attachment, attachment.name)
    })

    try {
      const res = await fetch(`${config.apiUrl}/hovedsider/reklamasjoner`, {
        method: "post",
        body: formData,
        headers: { "Content-Type": "multipart/form-data" }
      })

      const result = await res.json()

      if(!res.ok) {
        throw new Error(result.message || "En ukjent feil oppstod")
      }

      toast.success("Din henvendelsen er nå mottatt")
    } catch(e) {
      toast.error(e.message)
    }
    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="flex mb-8">
        <Input
          name="firstName"
          label="Fornavn"
          placeholder="Skriv inn ditt fornavn"
          className="mr-4 flex-1"
          required={true}
          register={register}
          errors={errors}
        />

        <Input
          name="lastName"
          label="Etternavn"
          placeholder="Skriv inn ditt Etternavn"
          className="ml-4 flex-1"
          required={true}
          register={register}
          errors={errors}
        />
      </div>

      <div className="flex mb-8">
        <Input
          name="address"
          label="Adresse"
          placeholder="Skriv inn ditt adresse"
          className="mr-4 flex-1"
          required={true}
          register={register}
          errors={errors}
        />

        <Input
          name="apartmentNumber"
          label="Leilighetsnummer"
          placeholder="Skriv inn ditt leilighetsnummer"
          className="ml-4 flex-1"
          register={register}
          errors={errors}
        />
      </div>

      <div className="flex mb-8">
        <Input
          name="damageLocation"
          label="Lokasjon/sted"
          placeholder="Skriv inn ditt skadestedet"
          className="mr-4 flex-1"
          register={register}
          errors={errors}
        />

        <Input
          name="discoveryDate"
          label="Dato for hendelse"
          placeholder="Vennligst velg en dato"
          className="ml-4 flex-1"
          type="date"
          register={register}
          errors={errors}
        />
      </div>

      <div className="flex mb-8">
        <Input
          name="email"
          label="E-post"
          placeholder="Skriv inn din e-post"
          className="mr-4 flex-1"
          type="email"
          required={true}
          register={register}
          errors={errors}
        />

        <Input
          name="phoneNumber"
          label="Telefonnummer"
          placeholder="Skriv inn ditt telefonnummer"
          className="ml-4 flex-1"
          register={register}
          errors={errors}
        />
      </div>

      <Input
        type="textarea"
        name="message"
        label="Detaljert beskrivelse"
        placeholder="Angi en beskrivelse"
        required={true}
        register={register}
        errors={errors}
      />

      <div className="mt-6 mb-8">
        <label
          className="input-label"
          htmlFor="attachments"
        >
          Vedlegg
        </label>

        <Uploader
          id="attachments"
          onDrop={onDrop}
        />

        <div className="mt-4">
          {attachments.map(({
            name
          }) => (
            <div
              key={name}
              className="my-2 flex items-center"
            >
              <button
                className="mr-4 px-2 rounded hover:bg-gray-100"
                onClick={() => { removeAttahment(name) }}
              >
                ×
              </button>

              {name}
            </div>
          ))}
        </div>
      </div>

      <button
        type="submit"
        className="btn-ghost mx-auto block relative"
        disabled={isLoading}
      >
        Meld inn saken

        <ActivityIndicator
          shown={isLoading}
          children={null}
        />
      </button>
    </form>
  )
}

export default KundeserviceForm
