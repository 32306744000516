import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import PageHeader from "../components/PageHeader"
import Meta, { MetaProps } from "../components/Meta"
import KundeserviceForm from "../components/KundeserviceForm"

interface KundeservicePageTemplateProps {
  title: string,
  text: string
}

const KundeservicePageTemplate: FC<KundeservicePageTemplateProps> = ({
  title,
  text
}) => {
  return (
    <main id="index-page">
      <section
        id="informasjon"
        className="px-6 py-16 relative bg-cover bg-bottom bg-green-700 text-center text-white"
      >
        <h1 className="title text">
          {title}
        </h1>

        <hr className="sep bg-white" />

        <div
          className="max-w-4xl mx-auto markdown"
          dangerouslySetInnerHTML={{
            __html: marked(text)
          }}
        />
      </section>

      <section
        id="meld-inn"
        className="py-16 text-sm p-6"
      >
        <div className="max-w-3xl mx-auto">
          <KundeserviceForm />
        </div>
      </section>
    </main>
  )
}

interface KundeservicePageProps {
  data: {
    markdownRemark: {
      frontmatter: KundeservicePageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const KundeservicePage: React.FC<KundeservicePageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <KundeservicePageTemplate
        title={frontmatter.title}
        text={frontmatter.text}
      />
    </>
  )
}

export default KundeservicePage

export const KundeservicePageQuery = graphql`
  query KundeservicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          css
          js
        }
        title
        text
      }
    }
  }
`
